<template>
  <div class="page">
    <div class="formcontainer">
      <div class="formbox">
        <h2>İYS API Bilgi Formu</h2>

        <div class="formhead">
          <div>
            <p>
              <b>İLETİ+</b> hizmetleri ve <b>İYS API </b>hakkında bilgi almak için <a href="mailto:satis@iys.org.tr" class="blue" >bize ulaşın</a>.
               <!-- İleti Yönetim Sistemi <b>İLETİ+</b> hizmetlerinden faydalanmak için <a href="mailto:satis@iys.org.tr" class="blue" >satis@iys.org.tr</a> adresinden ilgili ekibimize ulaşabilirsiniz. -->
            </p>
          </div>
          <br />
        </div>

        <!-- <div class="formpart">
          <div class="formfull">
            <div class="formleft">
              <form action="#" onSubmit="return false;" id="tab1">
                <div class="halfgroup">
                  <div class="half">
                    <div class="formline">
                      <div
                        :class="{ notvalid: problem.fullName }"
                        class="inputline"
                      >
                        <label>Ad Soyad:</label>
                        <input
                          type="text"
                          name="fullName"
                          v-model="fullName"
                          @input="problem.fullName = ''"
                        />
                        <div class="hint">{{ problem.fullName }}</div>
                      </div>

                      <div class="inputline">
                        <email
                          v-model="email"
                          :message="problem.email"
                          @input="problem.email = ''"
                        >
                        </email>
                      </div>

                      <div class="inputline">
                        <capcha
                          class="inputline"
                          v-model="captcha"
                          section="CONTACT-US"
                          :message="problem.captchaCode"
                          @input="problem.captchaCode = ''"
                        >
                        </capcha>
                      </div>


                    </div>

                    <div
                      class="btn"
                      :class="{ dsbl: !gonder_active }"
                      @click="gonder_active ? GONDER() : null"
                    >
                      <a>Gönder</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CustomError from "../../misc/errors";

export default {
  data() {
    return {
      fullName: "",

      email: {
        str: "",
        validated: false
      },

      captcha: {
        captchaId: "",
        captchaCode: ""
      },

      problem: {
        fullName: "",
        email: "",
        captchaCode: "",
        message: ""
      },

      message: ""
    };
  },

  mounted() {
    this.$store.commit("app/HIDE_DUYURU");
    if (this.$env.MOCK) {
      this.fullName = "Ali Muhlis";
      this.email = {
        str: "deneme@cicekcilik.com.tr",
        validated: true
      };
      this.message = "API Başvuru formu rica ediyoruz?";
    }
  },

  computed: {
    messageLen() {
      return this.message.length;
    },

    gonder_active() {
      return true;
      return (
        this.captcha.captchaCode.length > 1 &&
        this.fullName.length > 2 &&
        this.email.str.length > 3 &&
        this.message.length > 5
      );
    }
  },

  methods: {
    clearFields() {
      this.fullName = "";
      this.email.str = "";
      this.message = "";
    },

    async GONDER() {
      this.$store
        .dispatch("app/contact_us", {
          fullName: this.fullName,
          email: this.email.str,
          ...this.captcha,
          type: "API",
          message: "İYS API ile ilgili bilgi almak istiyorum"
        })
        .then(async res => {
          if (res.ok) {
            // _paq.push([
            //   "trackEvent",
            //   "Iletisim",
            //   "Api_Basvuru_Formu",
            //   "Success"
            // ]);
            await this.Alert(
              "Teşekkürler.<br>API erişimi hakkında en kısa sürede sizinle iletişime geçeceğiz.",
              "success"
            );
            this.clearFields();
            this.$root.$emit("reset_capcha");
          }
        })
        .catch(cerror => {
          // _paq.push(["trackEvent", "Iletisim", "Api_Basvuru_Formu", "Error"]);
          this.HandleValidationError(cerror);
        });
    }
  }
};
</script>

<style scoped>
iframe {
  margin: 0;
  height: 20vh;
  width: 100%;
}
.halfgroup {
  align-items: flex-start;
  justify-content: center;
}
.map {
  height: -webkit-fill-available;
}

.formline {
  padding: 1vw;
}

.adres {
  margin-top: 1vw;
}

.phone {
  display: flex;
}
.phone img {
  height: 1rem;
  margin-right: 0.2vw;
}

/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .formpart form .half,
  .half {
    width: 100% !important;
  }

  .formline .adresler {
    flex-direction: column;
  }
  .formline .adres {
    width: 100%;
    padding: 3vw;
  }
  .formline {
    padding: 3vw;
  }
}

/*  on tablets portrait ********************************************************************************************/

@media (orientation: portrait) and (min-width: 501px) {
  .formpart form .half,
  .half {
    width: 100% !important;
  }
}
</style>
